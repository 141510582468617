<script setup lang="ts">
import PanelComponent from './PanelComponent.vue'

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
});

const emit = defineEmits(['error', 'update:shared-data']);

// Example of using shared data
const sharedData = computed(() => props.panel.data.sharedData['some-panel-id'] || {})

const selectedWord = ref(null)

const handleWordClick = (word) => {
  console.log(word)
  selectedWord.value = word
  updatePanelData()
}

// Update shared data when a word is selected
const updatePanelData = () => {
  emit('update:shared-data', {
    selectedWord: selectedWord.value,
    // Add any other data you want to share
  })
}
</script>

<template>
  <PanelComponent :panel="props.panel" :height="props.height" :panel_id="props.panel_id"
     toolLabel="Visualise" dataKey="species">
    <template #default="{ data, tool_id, updateState }">
      <keep-alive>
        <div class="overflow-hidden w-full h-full p-0 m-0 rounded-md p-2 pl-4 pb-10">
          <ScrollArea class="w-full h-full">
            <div class="flex justify-center align-center items-center w-full h-full">
              <div v-if="props.panel">
                <WordCloud @click="handleWordClick" class="w-full h-full select-none" :words="props.panel?.data?.output?.species" :radius="370" />
              </div>
              <div v-else>
                <span alt="prop panel is undefined">Oops something went wrong</span>
              </div>
            </div>
          </ScrollArea>
        </div>
      </keep-alive>
      <!-- Display selected word details -->
      <div v-if="selectedWord" class="absolute bottom-4 left-4 bg-white dark:bg-gray-800 p-2 rounded shadow">
        Selected: {{ selectedWord }}
      </div>
      <!-- Display some shared data -->
      <div class="absolute top-4 right-4 bg-white dark:bg-gray-800 p-2 rounded shadow">
        Shared data: {{ sharedData }}
      </div>
    </template>
  </PanelComponent>
</template>